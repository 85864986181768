// eslint-disable-next-line import/prefer-default-export
export const nailSalonHero = {
  title: "Nail salon POS system",
  subtext:
    "Book more appointments and simplify tedious administrative tasks with an end-to-end platform that’s easy for you and your clientele.",
  PrimaryCta: {
    ctaTitle: "Learn more",
    ctaTarget: "/health-and-beauty/nail-salons/demo",
  },
};
export const nailSalonSubHero = {
  title: "Adapt and thrive",
  list: [
    "Improve your online presence to attract new clients",
    "Minimize no-shows & cancellations",
    "Increase repeat visits to grow revenue",
    "Provide easy payment options for your clients",
    "Comply with contact tracing requirements",
  ],
  subtext:
    "The pandemic changed everything. As a nail salon operator, you’ve gone through extraordinary measures to simply keep your business open. We’re here to help.",
  PrimaryCta: {
    ctaTitle: "Learn more",
    ctaTarget: "/health-and-beauty/nail-salons/demo",
  },
};

export const nailSalonWhiteGlove = {
  mainTitle: "We are there for you & your customers",
  title: "Personalized support from people who care",
  content:
    "You work hard to give your clients the best service. At SpotOn, we do the same. Our team of experts will work closely with you to assess your needs and create a solution that makes life easier for you, your technicians, and your clients. We’ll teach you how to get the most from your new technology, and we’ll continue to earn your business every day with local service and 24/7 support.",
  ctaInfoPrimary: {
    ctaTitle: "Learn more",
    ctaTarget: "/health-and-beauty/nail-salons/demo",
  },
};

export const nailSalonLargeFeatureA = {
  featureBlocks: [
    {
      blockTitle: "Simplify and accomplish more",
      blockSubTitle:
        "SpotOn gives you a simple solution to run and grow your business. Our cloud-based nail salon software is thoughtfully designed to streamline numerous time-consuming tasks, while creating a seamless client experience that will help you attract more customers and improve loyalty, leading to improved revenue.",
      blockImg: "olo-modified.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/health-and-beauty/nail-salons/demo",
      },
    },
  ],
};

export const nailSalonTrusted = [
  {
    title: "30 – 40%",
    subtext: "Increase in sales with online booking",
  },
  {
    title: "40%",
    subtext: "Decrease in no-shows with automated reminders",
  },
  {
    title: "31%",
    subtext: "Increase in sales with repeat clients",
  },
  {
    title: "25 – 95%",
    subtext: "Profit growth with a 5% increase in client retention",
  },
];

export const nailSalonMultiSection = {
  sectionTitle: "Software & payment solutions for nail salons",
  featureBlocks: [
    {
      blockTitle: "Book more appointments & reduce no-shows",
      blockSubTitle:
        "Let clients book appointments right from your website or Facebook page 24/7, while simplifying staff scheduling, taking payments, and improving client loyalty with SpotOn Appointments.",
      blockList: [
        "Online booking",
        "Interactive calendaring",
        "Text & email reminders",
        "No-show protection",
        "Contact tracing",
        "Built-in credit card processing",
      ],
      blockImg: "hair-a.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/health-and-beauty/nail-salons/demo",
      },
    },
    {
      blockTitle: "Keep clients coming back",
      blockSubTitle:
        "Increase sales by offering loyalty rewards that encourage your clients to visit your nail salon more often. SpotOn Loyalty is easy to set up, easy to run, and your clients will love it.",
      blockList: [
        "Create custom rewards",
        "Enroll customers during checkout",
        "Increase sales with each check-in & redemption",
      ],
      blockImg: "accountant-d.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/health-and-beauty/nail-salons/demo",
      },
    },
    {
      blockTitle: "Attract new customers online",
      blockSubTitle:
        "Why waste your time building your own website? Our specialists will build a stunning site for you at a do-it-yourself price, so you show up higher in searches and bring more clients through your door.",
      blockImg: "nails-website.png",
      blockList: [
        "Custom website design",
        "Web hosting",
        "Custom web address",
        "Self-service dashboard",
        "Optional logo design",
        "Lifetime support",
      ],
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/health-and-beauty/nail-salons/demo",
      },
    },
    {
      blockTitle: "Make marketing easy",
      blockSubTitle:
        "We make it easy to stay in touch with clients, whether it’s to announce new services, offer discounts, or simply say thank you. With SpotOn Marketing, you’ll be able to send branded emails and deals quickly and easily to every client who has booked an appointment or enrolled in your loyalty program.",
      blockImg: "marketing-outlined.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/health-and-beauty/nail-salons/demo",
      },
    },
    {
      blockTitle: "Improve your ratings on review sites",
      blockSubTitle:
        "Know what people are saying about you on popular review sites like Yelp, TripAdvisor, Google, and Facebook. Our online review management software makes it easy to monitor all of your online reviews in one place and get automated alerts when new reviews are posted—so you can not only monitor your reputation, but also improve it.",
      blockImg: "accountant-c.png",
      forceReverse: false,
      ctaPrime: {
        ctaTitle: "Learn more",
        ctaTarget: "/health-and-beauty/nail-salons/demo",
      },
    },
  ],
};

export const nailSalonCTA = {
  title: "Sign up for your consultation.",
  ctaImg: "cta-people.png",
  ctaInfo: {
    ctaTitle: "Learn more",
    ctaTarget: "/health-and-beauty/nail-salons/demo",
  },
};

export const nailSalonTestimonials = {
  title: "What other salon owners are saying",
  reviews: [
    {
      quote:
        "From the initial consultation, to ordering, to implementation and integration, the entire process of on-boarding the SpotOn POS System has been seamless and has exceeded our expectations.",
      reviewDate: "Mar 9, 2021",
      reviewer: "Pete M.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn continues to be an invaluable technology partner that listens well and proactively presents innovative solutions to drive our business forward.",
      reviewDate: "Feb 9, 2021",
      reviewer: "Ben P.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "SpotOn has given me the data access I need to manage my business from a macro as well as micro perspective. The software is easy to access and utilize.",
      reviewDate: "Dec 5, 2020",
      reviewer: "Pamela H.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "Every issue and question has been answered or addressed within hours, if not minutes.I can't say enough good about this company and software.",
      reviewDate: "Feb 24, 2021",
      reviewer: "Brandy R.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
    {
      quote:
        "From back office, digital marketing, built our website to take orders off of. The programming is great to allow you to do more tasks with better trained staff.",
      reviewDate: "Dec 1, 2020",
      reviewer: "Marcus O.",
      reviewerBio: "",
      rating: 5,
      reviewerPhoto: "capterra_logo.png",
    },
  ],
};

export const nailSalonArticles = {
  title: "Expert advice on growing your business",
  bgText: "Articles.",
};

export const nailSalonFaqs = {
  title: "Frequently Asked Questions.",
  ctaInfo: {
    ctaTitle: "View more",
    ctaTarget: "/health-and-beauty/nail-salons/demo",
  },
  list: [
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
    {
      title: "How do I get deals?",
      body: "You are automatically signed up for deals (meaning VIP offers) when you check into a business or add a business to your memberships through our consumer app. Then, you’ll receive push notifications and emails about new deals.",
    },
  ],
};

export const nailCar = {
  title: "We know health & beauty",
  subtext:
    "Our appointment and scheduling software is great for all kinds of salons, spas, and health & beauty providers.",
  slides: [
    {
      bg: "hair-salon.png",
      cardTitle: "Hair salon",
      cardContent: "More appointments, fewer no-shows",
      icon: "hair-salon.png",
      linkTo: "/health-and-beauty/hair-salons",
    },
    {
      bg: "tanning.png",
      cardTitle: "Tanning salons",
      cardContent: "Increase sales and get paid fast",
      icon: "tanning.png",
      linkTo: "/health-and-beauty/tanning-salon",
    },
    {
      bg: "dentist.png",
      cardTitle: "Dentists",
      cardContent: "Increase sales and get paid fast",
      icon: "dentist.png",
      linkTo: "/health-and-beauty/dentist",
    },
    {
      bg: "barber.png",
      cardTitle: "Barbershop",
      cardContent: "Increase sales and get paid fast",
      icon: "barber.png",
      linkTo: "/health-and-beauty/barber",
    },
  ],
};
