import React from "react";
import loadable from "@loadable/component";
import Layout from "../../../components/Layout/layout";
import SEO from "../../../components/seo";
import BHero from "../../../components/Hero/b-hero";
import {
  nailSalonHero,
  nailSalonSubHero,
  nailSalonWhiteGlove,
  nailSalonLargeFeatureA,
  nailSalonTrusted,
  nailSalonMultiSection,
  nailSalonCTA,
  nailSalonTestimonials,
  // nailSalonArticles,
  // nailSalonFaqs,
  nailCar,
} from "../../../data/nail-salon-data";
import { hbBTSlides, heroSuccess } from "../../../data/success-stories-data";
// import NoVisualsCustom from "../../../components/NoVisuals/NoVisualsCustom";
// import WhiteGlove from "../../../components/WhiteGlove/WhiteGlove";
// import LargeFeatures from "../../../components/LargeFeatures/LargeFeatures";
// import TrustedNumbers from "../../../components/TrustedNumbers/TrustedNumbers";
// import LargeCta from "../../../components/CTAs/LargeCta";
// import SuccessStories from "../../../components/SuccessStories/SuccessStories";
// import TestmonialReviews from "../../../components/TestimonialReviews/TestmonialReviews";
// import BusinessTypes from "../../../components/BusinessTypes/BusinessTypes";

import heroBg from "../../../images/hero-images/nail-salons-hero.png";

const NoVisualsCustom = loadable(() =>
  import("../../../components/NoVisuals/NoVisualsCustom")
);
const WhiteGlove = loadable(() =>
  import("../../../components/WhiteGlove/WhiteGlove")
);
const LargeFeatures = loadable(() =>
  import("../../../components/LargeFeatures/LargeFeatures")
);
const TrustedNumbers = loadable(() =>
  import("../../../components/TrustedNumbers/TrustedNumbers")
);
const LargeCta = loadable(() => import("../../../components/CTAs/LargeCta"));
const SuccessStories = loadable(() =>
  import("../../../components/SuccessStories/SuccessStories")
);
const TestmonialReviews = loadable(() =>
  import("../../../components/TestimonialReviews/TestmonialReviews")
);
const BusinessTypes = loadable(() =>
  import("../../../components/BusinessTypes/BusinessTypes")
);

const whiteGloveImport = "nail-salons-white-glove.png";

const index = () => {
  return (
    <Layout>
      <SEO
        title="Nail Salon POS System | Point of Sale Software | SpotOn"
        description="SpotOn's Nail Salon POS system offers flexible payment options, appointment booking, & marketing solutions to grow your business and keep customers coming back."
        image={`https://spoton.com/${heroBg}`}
        cannonical="https://www.spoton.com/health-and-beauty/nail-salons/"
      />
      <BHero sectionData={nailSalonHero} heroBg="nail-salons-hero.png" />
      <NoVisualsCustom sectionData={nailSalonSubHero} complex />
      <WhiteGlove
        sectionData={nailSalonWhiteGlove}
        whiteGloveBg={whiteGloveImport}
      />
      <LargeFeatures
        sectionData={nailSalonLargeFeatureA}
        style={{ marginTop: "128px" }}
      />
      <TrustedNumbers title="By the numbers" numbersArray={nailSalonTrusted} />
      <LargeFeatures
        sectionData={nailSalonMultiSection}
        style={{ marginTop: "128px !important" }}
      />
      <LargeCta sectionData={nailSalonCTA} />
      <SuccessStories sectionData={heroSuccess} slides={hbBTSlides} />
      <TestmonialReviews sectionData={nailSalonTestimonials} />
      {/*      <Articles
        sectionData={nailSalonArticles}
        blogEntries={tempData}
        cornerStone={false}
      /> */}
      <BusinessTypes sectionData={nailCar} />
      {/* <Faqs sectionData={nailSalonFaqs} /> */}
    </Layout>
  );
};

export default index;
